<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
            fill="#DDE0E9"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.0537 13.9324C15.0537 11.2083 17.17 9 19.7805 9C22.391 9 24.5073 11.2083 24.5073 13.9324C24.5073 16.6565 22.391 18.8648 19.7805 18.8648C17.17 18.8648 15.0537 16.6565 15.0537 13.9324ZM19.7805 10.58C18.0062 10.58 16.5679 12.0809 16.5679 13.9324C16.5679 15.7838 18.0062 17.2847 19.7805 17.2847C21.5548 17.2847 22.9931 15.7838 22.9931 13.9324C22.9931 12.0809 21.5548 10.58 19.7805 10.58Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5718 22.6414C13.8857 20.9536 16.1335 19.7702 19.7805 19.7702C23.4275 19.7702 25.6753 20.9536 26.9892 22.6414C28.2785 24.2976 28.5571 26.2996 28.561 27.7243C28.5655 29.4143 27.1576 30.4634 25.7413 30.4634H13.8197C12.4034 30.4634 10.9954 29.4143 11 27.7243C11.0039 26.2996 11.2825 24.2976 12.5718 22.6414ZM19.7805 21.3502C16.4618 21.3502 14.7015 22.4115 13.747 23.6377C12.7678 24.8954 12.5176 26.4766 12.5142 27.7287C12.5125 28.3384 13.0292 28.8834 13.8197 28.8834H25.7413C26.5317 28.8834 27.0485 28.3384 27.0468 27.7287C27.0434 26.4766 26.7931 24.8954 25.814 23.6377C24.8595 22.4115 23.0992 21.3502 19.7805 21.3502Z"
              fill="white"/>
    </svg>
</template>
